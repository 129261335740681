// $mygreen: #0acc82;
// $myblue: #243387;

$bluefrom: #25377e;
$blueto: #2daae1;

$orangefrom: #ffeb1f;
$orangeto: #e3362e;

$bluegradient: linear-gradient(to right, $bluefrom 26%, $blueto);
// $orangegradient: linear-gradient(to right, $orangefrom 26%, $orangeto);

$primary: #296FAE;
$primary-light: shift-color($primary, -20%);
$primary-xlight: shift-color($primary, -40%);
$primary-dark: shift-color($primary, 20%);
$primary-xdark: shift-color($primary, 40%);
$secondary: #F08B27;
$secondary-light: shift-color($secondary, -20%);
$secondary-xlight: shift-color($secondary, -40%);
$secondary-dark: shift-color($secondary, 20%);
$secondary-xdark: shift-color($secondary, 40%);


// navbar
$navbar-light-color: rgba($primary, 0.55) !default;
$navbar-light-hover-color: rgba($primary, 0.7) !default;
$navbar-light-active-color: $primary !default;
$navbar-light-disabled-color: rgba($primary, 0.3) !default;
$navbar-light-toggler-border-color: rgba($primary, 0.1) !default;

$nav-link-font-size: 1.1rem;
$link-decoration: none;
$enable-negative-margins: true;

// Alerts
$alert-color-scale: 10%;
