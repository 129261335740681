
$countdown-text-color: #296FAE;
$countdown-text2-color: #F08B27;

// $blueto: #2daae1;
// $secondary: #ec7229;

.countdown {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    font-weight: 500;

    &__block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.75);
        color: $countdown-text-color;
        padding: 1rem;
        margin: 0 2px;
    }

    &__digit {
        font-size: 10vw;
        line-height: 1;
        margin-bottom: 2px;

        @media (min-width: 980px) {
            font-size: 6rem;
        }
    }

    &__text {
        display: inline-block;
        text-transform: uppercase;
        font-size: 3vw;
        color: $countdown-text2-color;

        @media (min-width: 980px) {
            font-size: 1.5rem;
        }
    }
}
