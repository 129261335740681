@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 900;
    src: url("fonts/roboto/Roboto-Black.ttf ");
}

@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 900;
    src: url("fonts/roboto/Roboto-BlackItalic.ttf ");
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    src: url("fonts/roboto/Roboto-Bold.ttf ");
}

@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 700;
    src: url("fonts/roboto/Roboto-BoldItalic.ttf ");
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    src: url("fonts/roboto/Roboto-Medium.ttf ");
}

@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 500;
    src: url("fonts/roboto/Roboto-MediumItalic.ttf ");
}

@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 400;
    src: url("fonts/roboto/Roboto-Italic.ttf ");
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    src: url("fonts/roboto/Roboto-Regular.ttf ");
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    src: url("fonts/roboto/Roboto-Light.ttf ");
}

@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 300;
    src: url("fonts/roboto/Roboto-LightItalic.ttf ");
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 100;
    src: url("fonts/roboto/Roboto-Thin.ttf ");
}

@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 100;
    src: url("fonts/roboto/Roboto-ThinItalic.ttf");
}
