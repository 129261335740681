
.inscription {
    width: 100%;
    max-width: 768px;
}

$primary: #ab167e;
$secondary: #484846;
.loader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: rgba($color: $secondary, $alpha: 0.5);
}
